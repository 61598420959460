import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  Dialog,
  TextField,
  DialogTitle,
  DialogActions,
  Button,
  DialogContentText,
  DialogContent,
} from "@mui/material";
import { osName, browserName, deviceType } from "mobile-device-detect";
import md5 from "md5";
import ShowAlert from "./alert";
import SlackAlert from "../utils/slackAlert";
import useLanguage from "../utils/useLanguage";
import { getDomain, getSubdomain } from "../utils/utils";
import ViewsRouter from "./viewsRouter";
import { envEnums, qrStatusEnums } from "../enums/enums";
import Loading from "./loader";

const API_URL       = 'http://127.0.0.1:8000';
const ANALYTICS_URL = `http://127.0.0.1:8003`;



const API_ROUTES = {
  "sp":{
    main:"https://{env}online-qr-generator.com",
    analytics:"https://statistics.online-qr-generator.com",
  },
  "qcc":{
    a:{
      main:"https://{env}qr-generator.ai",
      analytics:"https://analytics.qr-generator.ai"
    },
    b:{
      main:"https://{env}qr.box",
      analytics:"https://analytics.qr.box"
    },
    i:{
      main:"https://{env}qr-code.io",
      analytics:"https://analytics.qr-code.io"
    }
  },
  local:{ main:API_URL,analytics:ANALYTICS_URL }
}
export default function Main() {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const query = useMemo(()=>(new URLSearchParams(location.search)),[location]) ;
  const [modal, showModal] = useState(false);
  const [data, setData] = useState(null);
  const [type, setType] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [showAlert,setAlert] = useState(false);
  const t = useLanguage();
  const [isStatisticsUpdated,setStatisticsUpdated] = useState(false);
  
  const getApiRoute = (service)=>{
    let apiDomain = '';
    try{
      const domainCode = envEnums.IS_SCANNED_PAGE ? envEnums.SCANNED_PAGE : envEnums.QCI;
      if(process.env.REACT_APP_CONFIG === 'production'){
        if(params.domain && params.domain !== 'p'){
          apiDomain = API_ROUTES[domainCode][params.domain][service];
        }else{
          apiDomain = API_ROUTES[domainCode][service];
        }
      }else{
        apiDomain = API_ROUTES.local[service];
      }
    } catch(e){
      // console.warn(e);
      apiDomain = API_ROUTES["sp"][service]; //fallback api
    }
    const environment = getSubdomain(window.location.origin,['dev','lang','staging']);   
    return String(apiDomain).replace('{env}',environment);
  }
  

  
  useEffect(() => {
    axios.get(`${getApiRoute('main')}/api/qr-code?uId=${params.uid}`)
    .then((result) => {
      if (result.data?.meta?.status) {
        if(!params.path && params.path !== 'p'){
          if(
            (result?.data?.data?.status === qrStatusEnums.Active || result?.data?.data?.status === qrStatusEnums.Draft) && 
            !result.data.data.is_expire && !result.data.data.is_archived
          ){
            updateStatics(
              getApiRoute('analytics'),
              {
                qr_code_id:result?.data?.data?.id,
                os_name:osName,
                browserName:browserName,
                deviceType:typeof deviceType === "undefined" ? 'desktop' : deviceType,
                utm_source:query.get('utm_source'),
                utm_medium:query.get('utm_medium'),
                utm_campaign:query.get('utm_campaign')
              },
              ()=>setStatisticsUpdated(true),
              ()=>setStatisticsUpdated(true),
            )
          }
          redirectPreview();
        }else{
          setStatisticsUpdated(true);
        }

        if (
          result?.data?.data?.status === qrStatusEnums.Active ||
          result?.data?.data?.status === qrStatusEnums.Draft
        ) {
          // qr code expire check
          if (result.data.data.is_expire === false) {
            let data = result.data.data.data;
            let qr_code_id = result.data.data;

            let type = result.data.data.type;
            let newObj = { ...data, ...qr_code_id };
            if (data["status"] === undefined) {
              setAlert({ type: "error", msg: `Something Went Wrong!` });
              slackAlert( getApiRoute(),params.uid, 0,'Something Went Wrong!');
            } else if (data.password) {
              showModal(true);
              setData(newObj);
              setType(type);
            } else {
              setRedirect(true);
              setData(newObj);
              setType(type);
            }
          } else {
            navigate("/deactivate", {
              state: { is_archived: false, uid: '', unique_pricing_link: result.data.data.unique_pricing_link },
            });
            }
        } else {
          noDataFoundNavigation();
        }
      } else {
        defaultNavigate();
      }
    })
    .catch((err) => defaultNavigate(err, {}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.uid]);

  const defaultNavigate = (err, uid) => {
    switch (err.response?.status) { 
      case 404:
        setRedirect(true);
        setType("default");
        break;
      case 500:
        setAlert({type:'error',msg:t('global.error.err_500')});
        slackAlert(getApiRoute(),params.uid, 500,err?.message);
        break;
      case 502:
        setAlert({type:'error',msg:t('global.error.err_502')});
        slackAlert(getApiRoute(),params.uid, 502,err?.message);
        break;
      case 0:
        setAlert({type:'error',msg:t('global.error.no_internet')});
        break;
      default:
        noDataFoundNavigation();
        break;
    }
  };
  const noDataFoundNavigation = () => {
    navigate("/no-data");
  };

  const replaceEncodedSymbols = (obj) => {
    for (let prop in obj) {    
      if (typeof obj[prop] === "string") {
        obj[prop] = obj[prop].replace(/&#039;/g, "'");
        obj[prop] = obj[prop].replace(/&lt;/g, "<");
        obj[prop] = obj[prop].replace(/&gt;/g, ">");       
        obj[prop] = obj[prop].replace(/&amp;/g, "&");       
      } else if (typeof obj[prop] === "object") {
        replaceEncodedSymbols(obj[prop]); 
      }
    }
  }

  
  const redirectPage = () => {
    replaceEncodedSymbols(data);
    
    const showLoadingScreen = (!isStatisticsUpdated && (
      (type === 'pdf' && data?.data?.directly_show_pdf) ||
      ['url', 'whatsapp', 'instagram'].includes(type)
    ));
    
    if(showLoadingScreen) {
      return <Loading />;
    }
    
    return redirect ? <ViewsRouter domain={params?.domain} type={type} data={data} /> : null;
  };

  const redirectPreview = ()=>{
    let domainIdentification = `/${getDomain(params?.domain)}`;

    if(domainIdentification === '/q') domainIdentification = '';

    navigate(`${domainIdentification}/p/${params.uid}`);
  }

  const slackAlert = (domain,uid,errCode,reason) => {
    if(process.env.REACT_APP_CONFIG === 'production'){
      SlackAlert(domain,uid,errCode,reason);
    }
  }
  

  return (
    <>
      {showAlert ? (
        <ShowAlert type={showAlert?.type} msg={showAlert?.msg} />
      ) : (
        <>
          {redirect ? (
            redirectPage()
          ) : (
            <PasswordModal
              open={modal}
              setRedirect={setRedirect}
              showModal={showModal}
              psw={data?.password}
            />
          )}
        </>
      )}
    </>
  );
}

const PasswordModal = ({ open, setRedirect, showModal, psw }) => {
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const t = useLanguage();

  const verifyPassword = () => {
    if (md5(password) === psw) {
      showModal(false);
      setRedirect(true);
    } else {
      setPasswordError(t('global.password.incorrect_pass'));
    }
  };

  useEffect(() => {
    window.onkeyup = (e) => {
      if (e.key === "Enter") {
        verifyPassword();
      }
    };
  });

  return (
    <Dialog open={open}>
        <DialogTitle>{ t('global.password.required_pass') }</DialogTitle>
        <DialogContent>
          <DialogContentText>
            { t('global.password.title') }
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label={ t('global.password.label') }
            type="password"
            fullWidth
            variant="standard"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordError("");
            }}
            error={passwordError ? true : false}
            helperText={passwordError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={verifyPassword}>{ t('global.password.enter_btn') }</Button>
        </DialogActions>
      </Dialog>
  )
}

function updateStatics(domain,data,successCallback,errorCallback){
  axios.post(
    `${domain}/api/analytics`,
    new URLSearchParams({
      os_name:data.os_name,
      browser_name:data.browserName,
      referrer_host:document.referrer?.split("/")[2] || "",
      referrer_path:document.referrer || "",
      device_type:data.deviceType,
      browser_language:navigator.language || navigator.userLanguage,
      qr_code_id:data.qr_code_id,
      utm_source:data.utm_source,
      utm_medium:data.utm_medium,
      utm_campaign:data.utm_campaign
    })
  )
  .then(successCallback)
  .catch(errorCallback)
}

